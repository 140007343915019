@import './Breakpoints.scss';

.FeaturedSection {
    display: flex;
    flex-direction: column;
    background-color:#cdd1d8;
    justify-content: center;
    align-items: center;
    @include breakpoint(desktop) {
        flex-direction: row;
    }

    &--alignRight {
        flex-direction: column;
        background-color: #e8eaed;

        @include breakpoint(desktop) {
            flex-direction: row-reverse;
        }

    }

    &__text {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        //margin: 50px 0px;
        text-align: center;
        max-width: 80%;

        @include breakpoint(desktop) {
            margin: 50px 100px 50px 50px;
            max-width: 60%;
        }

    }

    &__title {
        font-family: 'Quicksand', sans-serif;
        font-size: 35px;
        margin: 40px 0px 15px 0px;

        @include breakpoint(desktop) {
            font-size: 45px;
            margin: 15px 0px;
        }
    }

    &__description {
        font-family: 'Quicksand', sans-serif;
        font-size: 18px;

        @include breakpoint(desktop) {
            font-size: 20px;
        }
    }

    &__images {
        margin: 3vw 10vw;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__image {
        max-width: 300px;
        box-shadow: 0px 0px 5px black;
        border: 2px;

        @include breakpoint(phablet) {
            max-width: 400px;
        }

        @include breakpoint(desktop) {
            max-width: 500px;
        }
    }
}


a {
    color: #FF4019;
    text-decoration: none;
  }
  
  a:hover {
    color: #ba2c10;
  }