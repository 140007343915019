@import './Breakpoints.scss';

.PageFooter {
    background-color: #282c34;
    text-align: center;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &__text {
        font-family: 'Quicksand', sans-serif;
        color: white;
        font-size: 18px;
        margin: 10px;
    }

    &__copyright {
        color: #FF4019;
    }
    &__socialmedia {
    display: flex;
     a {
        padding: 0 10px;
        img {
            width: 26px;
            transition: transform .2s; /* Animation */
        }
        img:hover {
            transform: scale(1.3); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
          }
     }
    }
}
