.PageHeader {
  text-align: center;

  &__headshot {
    height: 40vmin;
    border-radius: 50%;
    //box-shadow: 0px 0px 20px 1px white;
  }
  
  &__header {
    font-family: 'Quicksand', sans-serif;
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  &__name {
    color: #FF4019;
  }

  &__profession {
    opacity: 0;
    animation: PageHeader-profession-fade 1s linear forwards;

    &--developer {
      animation-delay: 0s;
    }
   
    &--composer {
      animation-delay: 1s;
    }
  
    &--songwriter {
      animation-delay: 2s;
    }
  }

  &__button  {
    margin-top: 35px;
    background-color: transparent;
    border: none;
    outline: none;
    opacity: 0;
    animation: PageHeader-profession-fade 1s linear forwards;
    animation-delay: 3s;
  }

  &__arrow {
    font-size: 50px;
    color: #FF4019;
    transition: transform .3s;
    cursor: pointer;
  }

  &__arrow:hover {
    transform: scale(1.5); 
  }

}

@keyframes PageHeader-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes PageHeader-profession-fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
